import React from 'react';
import './App.css';

function App() {
  return (
    <div className="app">
      <div className="header">
        <img src='/tuul-transparent-horizontal-landing.png' alt='company logo' />
      </div>
      <div className="maintxt">
        <h2>Generando vínculos y ampliando el mercado laboral medico veterinario en México.</h2>
      </div>
      <div className='line'></div>
      <div className='our-app'>
        <h3>¿Qué hace nuestra app?</h3>
        <div>
          <div>
            <img src='dogtor.png' alt='estetoscopio'/>
            <p>Ayudar a los medicos veterinarios a hacer citas a domicilio.</p>
          </div>
          <div>
            <img src='hospital.png' alt='hospital veterinario'/>
            <p>Poner en contacto a tutores con veterinarios o con clinicas para que su mascota tenga el mejor tratamiento posible.</p>
          </div>
          <div>
            <img src='pets.png' alt='animales'/>
            <p>Se podrán encontrar veterinarios de todas especialidades y especies para que tu mascota tenga al medico veterinario que necesita.</p>
          </div>
        </div>
      </div>
      <div className='media'>
        <div>
            <a href='https://www.instagram.com/tuul_mx' target='_blank' rel="noopener noreferrer">
              <img src='instagram.png' alt='instagram' />
            </a>
            <a href='https://www.facebook.com/tuulmx' target='_blank' rel="noopener noreferrer">
              <img src='facebook.png' alt='facebook' />
            </a>
            <a href='https://twitter.com/tuul_mx' target='_blank' rel="noopener noreferrer">
              <img src='twitter.png' alt='twitter' />
            </a>
            <a href='https://medium.com/@zkikjgmcv' target='_blank' rel="noopener noreferrer">
              <img src='medium.png' alt='medium' />
            </a>
            <a href='https://www.linkedin.com/company/tu-ul' target='_blank' rel="noopener noreferrer">
              <img src='linkedin.png' alt='linkedin' />
            </a>
          </div>
        <div className='getouttahere'>
          <img src='app-with-cat.png' alt='aplicación móvil' />
        </div>
      </div>
      <div className='help'>
        <h3>Visitanos en Medium</h3>
        <p>En nuestra página de Medium subiremos artículos cortos e interesantes relacionados con las mascotas o temas veterinarios.</p>
        <p>A través de esta plataforma buscamos crear una comunidad de tutores y de medicos veterinarios para que puedan encontrar información relevante e interesante para sus pacientes o mascotas.</p>
        <p>Abajo te proporcionamos un link al artículo sobre nuestro equipo y nuestra misión.</p>
        <p className={'comunidad'}>Bienvenido a la comunidad TU'UL.</p>
        <a href='https://bit.ly/tuul-app' target='_blank' rel="noopener noreferrer">
          <button className='blue-button'>Vamos al artículo!</button>
        </a>
      </div>
      <div className='footer'>
        <p>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></p>
        <p>Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></p>
      </div>
    </div>
  );
}

export default App;
